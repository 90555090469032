//  Core

.app-sidebar {
  &--header {
    padding: 0 ($layout-spacer / 2);
    min-height: $header-height;
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: ($layout-spacer / 4);

    .expand-sidebar-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &::after {
      content: "";
      width: 100%;
      left: 0;
      bottom: 0;
      height: 1px;
      position: absolute;
    }
  }

  .toggle-mobile-sidebar-btn {
    display: none;
  }

  // Responsive

  @include media-breakpoint-down(md) {
    .toggle-mobile-sidebar-btn {
      display: block;
    }
  }
}

// Logo wrapper

.app-sidebar-logo {
  //display: flex;
  //align-items: center;
  margin: 0 auto;
  //img {
  //  max-width: 152px;
  //  width: 100%;
  //}
}

@-webkit-keyframes logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

// Themes

@import "themes/dark";
@import "themes/light";

// Modifiers

@import "modifiers/collapsed";
