// Bamburgh React Admin Dashboard with Reactstrap PRO Components


@import "components/accordions/base";
@import "components/avatars/base";
@import "components/badges/base";
@import "components/buttons/base";
@import "components/cards/base";
@import "components/dropdowns/base";
@import "components/featuresections/base";
@import "components/footersections/base";
@import "components/formscontrols/base";
@import "components/formsslider/base";
@import "components/formstoggleswitch/base";
@import "components/formswizard/base";
@import "components/guidedtours/base";
@import "components/headersections/base";
@import "components/herosections/base";
@import "components/icons/base";
@import "components/listgroups/base";
@import "components/modals/base";
@import "components/navigationmenus/base";
@import "components/notifications/base";
@import "components/pagination/base";
@import "components/popovers/base";
@import "components/progressbars/base";
@import "components/ribbons/base";
@import "components/scrollable/base";
@import "components/searchbars/base";
@import "components/tabs/base";
@import "components/tables/base";
@import "components/timelines/base";
@import "components/tooltips/base";

.auth-logo {
  padding: 0 10%;
  margin: 30px 0 20px;
  text-align: center;
  @media (min-width: 768px) {
    margin: 0 0 20px;
  }
  img {
    max-width: 100%;
  }
}

.avatar-button__edit {
  background-color: $secondary;
}

.bg-composed-wrapper__hero {
  opacity: 1;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  overflow: hidden;
  filter: grayscale(0%);
  //position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(180deg, #D9D9D9 0%, #FFFFFF 32.29%);
    opacity: 0.7;
  }
}

.form-control.is-invalid {
  border: 1px solid #F83245 !important;
}
input.is-invalid.input-password {
  background-image: none;
}

input.is-invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.invalid-feedback.show {
  display: block;
}

.is-invalid {
  .rc-time-picker-input {
    border-color: #F83245;
  }
}



.error-message {
  text-align: center;
  color: #F83245;
  margin-bottom: 5px;
}

.hero-wrapper {
  margin-top: -1.5rem;
}

.btn-white {
  display: inline-block;
  background-color: #fff;
  color: $primary;
  box-shadow: 0 0.25rem 0.55rem rgba(255, 255, 255, 0.35)!important;
  font-weight: 500;
  border: 1px solid $primary;
  outline: none !important;
  &--bd-white {
    border-color: #fff;
  }
  &--bd-red {
    //border-color: $primary;
  }
  &:hover {
    background-color: #fff;
    color: $primary;
    border-color: $primary;
    box-shadow: 0 0.25rem 0.55rem rgba(255, 255, 255, 0.5) !important;
  }
  &:active {
    background-color: #fff !important;
    box-shadow: none !important;
    color: $primary !important;
    border-color: inherit !important;
  }
}

.btn-icon-blue {
  background: #C4F0FF;
  color: #00AFE9;
  &:hover,
  &:focus,
  &:active {
    background: #C4F0FF !important;
    color: #00AFE9 !important;
  }
}

.btn-icon-green {
  background: #DAF1D0;
  color: #5EB336;
  &:hover,
  &:focus,
  &:active {
    background: #DAF1D0 !important;
    color: #5EB336 !important;
  }
}

.btn-icon-red {
  background: #FEE6E9;
  color: #F72237;
  &:hover,
  &:focus,
  &:active {
    background: #FEE6E9 !important;
    color: #F72237 !important;
  }
}

.btn-icon-orange {
  background: #FBEDBF;
  color: #E2B419;
  &:hover,
  &:focus {
    background: #FBEDBF !important;
    color: #E2B419 !important;
  }
}

.btn-icon-disabled {
  background-color: transparent !important;
  color: #B6C1C2 !important;
  &:hover {
    background-color: transparent;
    color: #B6C1C2;
  }
}

.btn-svg {
  border: none !important;
  background-color: transparent !important;
}

table.table-alternate-spaced  {
  tbody {
    .avatar-icon {
      box-shadow: none;
    }
    tr.tr-disabled {
      color: #6C7374;
      .avatar-icon {
        opacity: .7;
      }
      td::before {
        background: #F6F8F8;
      }
      &:hover {
        td::before {
          background: #F6F8F8;
        }
      }
    }
  }
}

.pagination .page-item {
  &.disabled {
    * {
      color: #B6C1C2;
    }
  }
  .page-link {
    border: none;
    color: $black;
    //margin: 0 2px;
    font-weight: 300;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      background-color: transparent;
      color: $primary;
    }
  }
}

.pagination-first .page-item.active .page-link {
  background-color: $primary;
  box-shadow: 0 3px 9px #EA98A0;
}


.input-group-text {
  //padding: 0.53rem;
}

.input-group-date {
  display: flex;
}

.rdp-input-group {
  width: calc(100% - 57px);
  input {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 0.29rem !important;
    border-top-right-radius: 0.29rem !important;
  }
  .rdp-addon {
    display: none;
  }
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

input[type="time"] {
  padding: 0 5px !important;
  text-align: center !important;
}


.rdp-calendar {
  td.bg-primary {
    color: #fff !important;
  }
}

.user-avatar-status {
  background-color: #36B377;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  bottom: -1px;
  right: -1px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 10px !important;
  }
}

.input-group-text {
  background-color: #F6F8F8;
  color: #6C7374;
}

.m-status-new {
  color: #009FAD;
}

.m-status-completed {
  color: #65C235;
}

.m-status-progress {
  color: #FE6E05;
}

.rc-time-picker-panel-combobox{
  display: flex;
}

.rc-time-picker-50 {
  width: calc((100% - 57px) / 2);
  .rc-time-picker-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.rc-time-picker-n-r {
  .rc-time-picker-input {
    border-radius: 0 !important;
    border-right: none;
  }
}

.rc-time-picker-single {
  width: 120px;
  .rc-time-picker-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.rc-time-picker-clear {
  display: none;
}

.rc-time-picker-input[disabled] {
  background-color: #F6F8F8 !important;
}




.form-control-time {
  padding: 0;
  .rc-time-picker-input {
    border: none;
  }
}

.rc-time-picker-input {
  color: $black;
}

.rc-time-picker-input:hover:not([disabled]) {
  border-color: #d1d2db;
  box-shadow: none;
}


.rc-time-picker-input:focus:not([disabled]) {
 outline: none;
}

.rc-time-picker-input::placeholder {
  color: #989898;
}

.rc-time-picker-clear-icon {
  display: none;
}

.rc-time-picker-input {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker-wrapper {
  width: calc(100% - 55px);
}

.rounded-circle {
  position: relative;
}

.avatar-icon {
  background-color: #009FAD;;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  box-shadow: none;
  background-size: cover;
  background-position: 50% 50%;
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }
}

select.form-control {
  //text-transform: capitalize;
}

.form-control-file {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.avatar-button__edit {
  cursor: pointer;
}

.custom-dropdown {
  .dropdown-menu {
    padding: 0;
    box-shadow: none;
    .btn {
      width: 100%;
      height: 44px;
      &:hover {
        //border-color: #fff;
        transform: none;
      }
      &:focus {
        //background-color: #fff;
        //color: $primary;
      }
    }
  }
}


.alert {
  position: fixed;
  top: 20px;
  right: 20px;
  max-width: calc(100vw - 20px - 20px);
  z-index: 10000000000000;
}


.app-content--inner__wrapper > div {
  transform: none !important;
}

.avatar-lg {
  .avatar-icon-wrapper {
    border: 1px solid #E5E5E5;
    //background-color: #E5E5E5;
    background-position: 50% 50%;
    background-size: cover;
  }
}

.tab-nav {
  margin-bottom: 30px;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
  @media (max-width: 480px) {
    justify-content: space-around;
    margin-bottom: 15px;
  }
  &__item {
    margin-right: 55px;
    margin-bottom: 20px;
    @media (max-width: 700px) {
      margin-right: 25px;
    }
    @media (max-width: 480px) {
      margin: 0 10px 10px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__link {
    position: relative;
    color: #989898 !important;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    transition: .2s;
    padding-left: 0;
    padding-right: 0;
    &:hover {
      background-color: transparent !important;
      color: #BF1E2E !important;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: -3px;
      right: 0;
      left: 0;
      background-color:#BF1E2E;
      height: 3px;
      transition: .2s;
      opacity: 0;
    }
    &--active {
      color: #BF1E2E !important;
      &:before {
        opacity: 1;
      }
    }
  }
}


.invalid-feedback {
  white-space: break-spaces;
}

.error-title {
  color: #BF1E2E;
  font-size: 100px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 300px;
  }
}

.badge-position--bottom-right.badge-circle {
  background-color: #FFDBB1;
  svg {
    width: 17px !important;
    height: 17px !important;
    path {
      stroke: #FE6E05;
    }
  }
  .form-control-file {
    cursor: pointer;
  }
}
.badge-position--bottom-right-company.badge-circle {
  right: 0;
  bottom: 0;
  cursor: pointer;
}


.app-content--inner__wrapper {
  &>div {
    opacity: 1!important;
    transform: none !important;
  }
}

.app-sidebar--header .expand-sidebar-btn,
.app-sidebar--header .collapse-sidebar-btn {
  background-color: #EDEDED;
}


.rounded-circle {
  .error-message {
    line-height: 1;
    text-align: left;
    font-size: 11px;
    width: 300px;
    max-width: calc(100vw - 60px);
  }
}


.app-sidebar-logo {
  display: block;
  padding: 3px 10px;
  width: 100%;
  text-align: center;
  img {
    //width: 100%;
    width: auto;
    max-width: 100%;
    max-height: 68px;
  }
}

#focus-bug {
  position: relative;
  z-index: -999999;
  width: 1px;
  height: 1px;
  border: none;
  background-color: transparent;
  padding: 0;
  opacity: 0;
}

.btn-links {
  cursor: pointer;
  font-weight: 500;
  color: #BF1E2E;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  outline: none !important;
  &:disabled {
    opacity: 0.65;
    cursor: default;
  }
}

.nav-link-simple {
  cursor: pointer;
  &:hover {
    color: #BF1E2E;
  }
}


.company-logo {
  background-color: #fff;
  padding: 6px;
  display: flex;
  span {
    height: 80px;
    color: #000;
    font-size: 30px;
    line-height: 1.1;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    pointer-events: none;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.company-title {
  padding: 5px;
  min-height: 80px;
  color: #000;
  font-size: 30px;
  line-height: 1.1;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
  pointer-events: none;
  border: 2px solid #eeeff8 !important;
}